.pages-stage-stagecard {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.pages-stage-stagecard .tool-bar {
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  display: flex;
}
.pages-stage-stagecard .tool-bar .control {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  align-items: center;
  display: flex;
  color: rgba(0, 0, 0, 0.65);
}
.pages-stage-stagecard .tool-bar .control > :hover {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}
.pages-stage-stagecard .tool-bar .control > :active {
  color: #1890ff;
  cursor: pointer;
}
.pages-stage-stagecard .tool-bar .control svg.recovery-icon path {
  fill: rgba(0, 0, 0, 0.65);
}
.pages-stage-stagecard .tool-bar .control svg.recovery-icon-disabled {
  cursor: not-allowed;
}
.pages-stage-stagecard .tool-bar .control svg.recovery-icon-disabled path {
  fill: rgba(0, 0, 0, 0.25);
}
.pages-stage-stagecard .tool-bar .control svg.recovery-icon:hover {
  cursor: pointer;
}
.pages-stage-stagecard .tool-bar .control svg.recovery-icon:hover path {
  fill: rgba(0, 0, 0, 0.85);
}
.pages-stage-stagecard .tool-bar .control svg.recovery-icon:active {
  cursor: pointer;
}
.pages-stage-stagecard .tool-bar .control svg.recovery-icon:active path {
  fill: #1890ff;
}
.pages-stage-stagecard .tool-bar .auxiliary-input {
  display: flex;
  margin-left: 24px;
  align-items: center;
}
.pages-stage-stagecard .main-canvas {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.pages-stage-stagecard .main-canvas .card-container {
  flex: 1;
  margin-left: 48px;
  margin-right: 48px;
  margin-bottom: 48px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pages-stage-stagecard .main-canvas .card-container .svg-container {
  height: 300px;
}
.pages-stage-stagecard .main-canvas .card-container .card-footer {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pages-stage-stagecard .main-canvas .form-container {
  padding: 16px 23px;
  border-left: 1px solid rgba(0, 0, 0, 0.09);
}
.modal-option-container {
  display: flex;
  justify-content: space-around;
}
.instruction-card-preview-modal {
  transform-origin: top !important;
  transform: scale(1.5, 1.5);
}
