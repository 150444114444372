.report-rp-container .ant-table-thead > tr > th.ant-table-selection-column,
.report-rp-container .ant-table-tbody > tr > td.ant-table-selection-column {
  max-width: 16px;
  min-width: 16px;
  width: 16px;
}
@media print {
  @page {
    size: A4 portrait;
    bleed: auto;
  }
  .report-rp-container .ant-table-thead > tr > th,
  .report-rp-container .ant-table-tbody > tr > td {
    font-size: 12px;
    padding: 3px 3px !important;
    border-bottom: 1px solid black;
  }
}
