.report-invoice-container {
  position: relative;
  width: 241mm !important;
  height: 140mm !important;
  margin: 0 auto !important;
  padding: 10mm 20mm !important;
  font-size: 12px !important;
  box-shadow: none;
  font-family: serif;
  color: black;
}
.report-invoice-container .table {
  width: 100%;
  border-top: 1px solid black;
  border-left: 1px solid black;
  margin-top: 6px !important;
}
.report-invoice-container .table .tr {
  display: flex;
}
.report-invoice-container .table .tr .td {
  text-align: center;
  flex: 1;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-size: 14px !important;
  padding: 3px 6px !important;
}
.report-invoice-container .table .tr .td.field {
  font-size: 16px !important;
}
.report-invoice-container .title-container {
  display: flex;
}
.report-invoice-container .title-container .name {
  flex: 1;
  font-size: 18px !important;
}
.report-invoice-container .title-container .meta {
  font-size: 12px !important;
}
.report-invoice-container .check-container .table .tr.content .td {
  height: 30px;
}
