.form-patient-intra-oral-teeth-options .ant-checkbox-group {
  min-width: 480px;
  max-width: 480px;
}
.form-patient-intra-oral-teeth-options .ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 0;
}
.form-patient-intra-oral-teeth-options .ant-checkbox-wrapper + span,
.form-patient-intra-oral-teeth-options .ant-checkbox + span {
  width: 30px;
  padding-left: 0;
  display: block;
}
