.secondary {
  color: rgba(0, 0, 0, 0.45);
}
.light {
  color: rgba(0, 0, 0, 0.25);
}
.invisible {
  display: none !important;
}
.modal-body {
  padding: 0 40px;
}
.modal-body .upload-info {
  margin: 16px 0;
}
.modal-body .add-files {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-body .uploading-file-count {
  color: #1890ff;
  margin-right: 4px;
}
.ant-table-tbody > tr > td {
  padding: 4px 8px !important;
}
.confirm-cancel i {
  color: #ff4d4f !important;
}
.confirm-cancel .red {
  color: #ff4d4f;
}
.file-process-wrapper .file-name {
  display: flex;
  align-items: center;
}
.file-process-wrapper .file-name i {
  margin-right: 8px;
}
.file-process-wrapper .file-name .text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file-process-wrapper .align-left {
  margin-left: calc(14px + 8px);
}
.file-process-wrapper .no-file {
  padding: 6px 0;
}
