/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pages-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
}
.pages-error .image-container {
  flex: 1;
}
.pages-error .image-container::before,
.pages-error .image-container::after {
  content: ' ';
  display: table;
}
.pages-error .image-container::after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.pages-error .image-container .image {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.pages-error .description-container {
  flex: 1;
}
.pages-error .description-container h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.pages-error .description-container .desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.pages-error .description-container .actions a:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .pages-error .image-container {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .pages-error {
    display: block;
    text-align: center;
  }
  .pages-error .image-container {
    padding-right: 0;
    margin: 0 auto 24px;
  }
}
@media screen and (max-width: 480px) {
  .pages-error .image-container {
    margin-bottom: -24px;
    overflow: hidden;
  }
}
