:root {
  --a4-width: 210mm !important;
  --a4-height: 297mm !important;
}
.report-qc-container .qc-table-container .ant-table-thead > tr > th.ant-table-selection-column,
.report-qc-container .qc-table-container .ant-table-tbody > tr > td.ant-table-selection-column {
  max-width: 16px;
  min-width: 16px;
  width: 16px;
}
.report-qc-container .qc-label-container {
  margin-left: 5px !important;
}
.report-qc-container .qc-label-container > :first-child {
  margin-top: 0.5mm !important;
}
.report-qc-container .qc-label-container .qc-item-container {
  height: 29.5mm !important;
}
.report-qc-container .qc-label-container .qc-item-container .qc-item {
  display: inline-block;
  padding: 2mm 1mm 1mm 1mm !important;
  width: 69mm !important;
}
.report-qc-container .qc-label-container .qc-item-container .qc-item .qc-info {
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 0.4mm 4mm !important;
  line-height: 1.8 !important;
  font-size: 1.2em;
}
.report-qc-container .qc-label-container .qc-item-container .qc-item .qc-info .info-title {
  opacity: 0.8 !important;
}
.report-qc-container .qc-label-container .qc-item-container .qc-item .qc-info .step-text {
  margin-bottom: 0;
}
.report-qc-container .qc-label-container .qc-item-container .qc-item .qc-info img {
  position: absolute;
  width: 15mm !important;
  right: 0;
  bottom: 0;
}
.report-qc-container .qc-wrapper-container .transform-container {
  transform-origin: 35.4% 50.2%;
  height: 210mm !important;
  width: 297mm !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container {
  height: 105mm !important;
  display: flex;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item {
  width: 74.2mm !important;
  height: 105mm !important;
  padding-left: 2mm !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header {
  display: flex;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header .header-left {
  width: 66.6%;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header .header-left .wrapper-info {
  width: 100%;
  display: flex;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header .header-left .wrapper-info h4 {
  padding-left: 2mm !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header .header-left .wrapper-info section {
  display: inline-block !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header .header-left .wrapper-logo {
  width: 100%;
  margin: 2mm 0 5mm 0 !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header .header-right {
  width: 33.3%;
  position: relative;
  padding: 2mm 2mm 0 0 !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header .header-right .wrapper-number-bg {
  width: 100%;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-header .header-right .wrapper-number-text {
  font-weight: bold;
  font-size: 3em;
  right: 11mm;
  top: 6.7mm;
  position: absolute;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-notification {
  font-size: 12px;
  padding-top: 2mm !important;
  padding-left: 2mm !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-notification ul {
  list-style-type: decimal;
  padding-left: 6mm !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-note {
  display: flex;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-note p {
  writing-mode: vertical-lr;
  text-orientation: upright;
  height: 20mm;
  background-color: black !important;
  color: white !important;
  margin: 0;
  text-align: center !important;
  padding: 4mm 1mm !important;
  border-radius: 20% 0 0 20%;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .item-note section {
  width: 85%;
  border-radius: 0 5% 5% 0;
  border: 1px solid;
  padding-left: 2mm !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .qr-code {
  padding: 3mm !important;
  text-align: center;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .qr-code img {
  width: 30mm !important;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .qr-text {
  text-align: center;
}
.report-qc-container .qc-wrapper-container .transform-container .qc-item-container .qc-item .qr-text b {
  border-bottom: 2px solid;
}
.report-qc-container .qc-newbagwrapper-container .transform-container {
  width: var(--a4-width);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
}
.report-qc-container .qc-newbagwrapper-container .transform-container > * {
  border: 1px solid #000;
  width: 69mm !important;
  height: 29.5mm !important;
  padding: 2mm 1mm 2mm 1mm !important;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header {
  position: relative;
  display: flex;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header > * {
  display: flex;
  align-items: center;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-left {
  padding: 0 2mm !important;
  border-right: 1px solid #000;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-left .wrapper-number-text {
  font-size: 42px;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-right .wrapper-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50mm;
  height: 100%;
  line-height: 16px;
  padding: 0 0 11.2mm 2mm !important;
  color: #000;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-right .wrapper-info h4 {
  margin: 0;
  font-weight: 400;
  padding-top: 2mm !important;
  font-size: 11pt;
  font-weight: 500;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-right .wrapper-info .goodsCodeItem,
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-right .wrapper-info .date {
  font-size: 7pt;
  font-weight: 400;
  padding-top: 2mm !important;
  transform: scale(0.85);
  position: absolute;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-right .wrapper-info .wrapper-info-row {
  display: flex;
  justify-content: space-between;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-right .wrapper-info .goodsCodeItem {
  display: flex;
  width: 61mm;
  bottom: 3mm;
  right: -2mm;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-header .header-right .wrapper-info .date {
  bottom: 8mm;
  left: 11.2mm;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-content {
  font-size: 0.8em;
  overflow: hidden;
  display: flex;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-content .step-head {
  padding: 0 2mm !important;
  border-right: 1px solid #000;
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1em;
}
.report-qc-container .qc-newbagwrapper-container .transform-container .item-content .step-content {
  padding-left: 2mm !important;
  display: flex;
  line-height: 1.1;
  word-break: break-word;
  flex-flow: column wrap;
  justify-content: center;
}
@media print {
  @page {
    size: A4;
  }
  .report-qc-container * {
    visibility: hidden;
  }
  .report-qc-container .form-bar {
    display: none;
  }
  .report-qc-container .form-bar.active {
    display: block !important;
    visibility: visible !important;
  }
  .report-qc-container .form-bar.active #print-info {
    display: block !important;
    visibility: visible !important;
  }
  .report-qc-container .form-bar.active #print-info * {
    display: block !important;
    visibility: visible !important;
  }
  .report-qc-container .qc-table-container {
    display: block;
    visibility: visible !important;
    padding: 6px !important;
  }
  .report-qc-container .qc-table-container * {
    visibility: visible !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper {
    display: block !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th,
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th,
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td,
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td {
    font-size: 14px !important;
    padding: 6px 0 !important;
    border-bottom: 1px solid black !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(2),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(2),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(2),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(2) {
    width: 8mm !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(3),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(3),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(3),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(3),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(10),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(10),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(10),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(10),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(11),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(11),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(11),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(11) {
    width: 6mm !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(5),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(5),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(5),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(5),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(9),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(9),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(9),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(9) {
    width: 18mm !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(4),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(4),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(4),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(4),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(6),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(6),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(6),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(6) {
    width: 18mm !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(7),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(7),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(7),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(7) {
    width: 18mm !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th:nth-child(8),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th:nth-child(8),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td:nth-child(8),
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td:nth-child(8) {
    width: 18mm !important;
  }
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr th.ant-table-selection-column,
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr th.ant-table-selection-column,
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-thead > tr td.ant-table-selection-column,
  .report-qc-container .qc-table-container .ant-table-wrapper .ant-table-tbody > tr td.ant-table-selection-column {
    display: none;
  }
  .report-qc-container .break-line {
    page-break-after: always;
  }
  .report-qc-container .qc-label-container {
    display: block !important;
    visibility: visible !important;
  }
  .report-qc-container .qc-label-container * {
    visibility: visible !important;
  }
  .report-qc-container .qc-wrapper-container {
    display: block !important;
    visibility: visible !important;
  }
  .report-qc-container .qc-wrapper-container h4 {
    line-height: 2 !important;
  }
  .report-qc-container .qc-wrapper-container ul {
    margin-bottom: 4mm !important;
  }
  .report-qc-container .qc-wrapper-container .item-note p {
    -webkit-print-color-adjust: exact;
  }
  .report-qc-container .qc-wrapper-container .transform-container {
    transform: rotate(90deg) !important;
    page-break-after: always;
    height: 210mm !important;
    width: 297mm !important;
  }
  .report-qc-container .qc-wrapper-container .transform-container:last-child {
    page-break-after: avoid;
  }
  .report-qc-container .qc-wrapper-container * {
    visibility: visible !important;
  }
  .report-qc-container .qc-newbagwrapper-container * {
    visibility: visible !important;
  }
  .report-qc-container .qc-newbagwrapper-container .transform-container {
    page-break-after: always;
  }
  .report-qc-container .qc-newbagwrapper-container .transform-container > * {
    border: none;
  }
  .report-qc-container .qc-newbagwrapper-container .transform-container:last-child {
    page-break-after: avoid;
  }
}
.report-qc-instruction-print {
  width: 210mm;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 1px 1px 1px 1px #ebebeb;
}
.report-qc-instruction-print #instruction-image-container {
  width: 105mm;
  height: 74.25mm;
  border-bottom: 1px dashed lightgray;
}
.report-qc-instruction-print #instruction-image-container:nth-child(2n + 1) {
  border-right: 1px dashed lightgray;
}
@media print {
  .report-qc-instruction-print {
    box-shadow: none !important;
  }
  .report-qc-instruction-print #instruction-image-container {
    border: none !important;
  }
  .report-qc-instruction-print #instruction-image-container svg.instruction-container {
    height: 24mm;
  }
}
