.sticky-row {
  width: 100%;
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 1;
}
.sticky-row > div {
  margin-bottom: 8px;
}
.sticky-row > div:last-child {
  margin: 0;
}
.sticky-row .edited-count {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 16px;
  margin-left: 8px;
}
.sticky-row .edited-count i {
  margin-right: 8px;
}
.sticky-row .add-button {
  margin-left: auto;
}
