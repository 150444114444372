.row {
  display: flex;
}
.row .item {
  flex: 1;
}
.row .item:last-child {
  flex: 2;
}
.row .item .status {
  min-height: 45px;
}
.row .item .position,
.row .item .status {
  padding: 4px 0;
  margin-left: -1px;
  margin-top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.row .clickable {
  cursor: pointer;
}
.row .clickable:hover .position,
.row .clickable:hover .status {
  color: #1890ff;
  border-color: #1890ff;
}
.row + .row {
  margin-top: 16px;
}
