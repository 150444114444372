.account-table-divider {
  margin: 16px 0;
}
.patient-type-field,
.doctor-filter-field {
  margin-bottom: 16px;
}
.radio-button {
  margin-right: 12px;
  padding: 4px;
  border-radius: 5px;
}
.account-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account-table-header h1 {
  margin: 0;
}
.report-invoice-account-container {
  width: 210mm;
  margin: 0 auto;
}
.invoice-account-table-container {
  position: relative;
  width: 210mm;
  height: auto;
  margin: 0 auto !important;
  padding: 24px !important;
  font-size: 12px;
  line-height: 1.5;
  box-shadow: 0 0 3px lightgray;
  font-family: serif;
  color: black;
}
@media print {
  .invoice-account-table-container {
    box-shadow: none;
  }
  @page {
    margin: 0 auto;
    size: A4 portrait;
  }
  .invoice-account-table-container .btn-print {
    display: none;
  }
}
.invoice-account-table-container .btn-print {
  position: absolute;
  top: 16px;
  right: 16px;
}
.invoice-account-table-container .table {
  width: 100%;
  border-top: 1px solid black;
  border-left: 1px solid black;
  margin-top: 6px;
}
.invoice-account-table-container .table .tr {
  display: flex;
}
.invoice-account-table-container .table .tr .td {
  text-align: center;
  flex: 1;
  border-top: 1px solid black;
  border-right: 1px solid black;
  font-size: 14px;
  padding: 3px !important;
}
.invoice-account-table-container .table .tr .td.field {
  font-size: 16px;
}
.invoice-account-table-container .table .tr .td:nth-child(4) {
  flex: 0.3;
}
.invoice-account-table-container .table .tr .td:nth-child(5),
.invoice-account-table-container .table .tr .td:nth-child(6),
.invoice-account-table-container .table .tr .td:nth-child(7),
.invoice-account-table-container .table .tr .td:nth-child(8) {
  flex: 0.5;
}
.invoice-account-table-container .table .tr .td:nth-child(2) {
  flex: 1.5;
}
.invoice-account-table-container .table .tr .td:nth-child(1),
.invoice-account-table-container .table .tr .td:nth-child(9) {
  flex: 0.8;
}
.invoice-account-table-container .table .tr:first-child .td {
  border-top: 0;
}
.invoice-account-table-container .table .tr:last-child .td {
  border-bottom: 1px solid black;
}
.invoice-account-table-container .table .tr.addTop:not(:nth-child(2)) .td {
  border-top: 3px solid black;
}
.invoice-account-table-container .title-container {
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.invoice-account-table-container .title-container .title {
  border-bottom: 1px solid;
}
.invoice-account-table-container .info-container {
  display: flex;
  font-size: 14px;
}
.invoice-account-table-container .info-container .clinic-container {
  flex: 1;
}
.invoice-account-table-container .info-container .clinic-container .clinic-name {
  font-size: 16px;
  font-weight: bold;
}
.invoice-account-table-container .pay-container {
  display: flex;
  font-size: 14px;
}
.invoice-account-table-container .pay-container .total {
  display: flex;
  border: solid;
  flex: 1;
  flex-direction: column;
  justify-content: space-around !important;
  padding: 0 5px !important;
}
.invoice-account-table-container .pay-container .total .row {
  display: flex;
  justify-content: space-between;
}
.invoice-account-table-container .pay-container .info {
  border: solid;
  flex: 2;
  padding: 5px !important;
}
.invoice-account-table-container .pay-container .info ul {
  list-style: decimal !important;
  padding-left: 20px !important;
}
.invoice-account-table-container .sov-container {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}
.invoice-account-table-container .sov-container span:not(:first-child) {
  margin-left: 15px;
}
