.components-layout-breadcrumb {
  flex: 1;
  margin-bottom: 8px;
}
.components-layout-breadcrumb .ant-breadcrumb > span:last-child a {
  color: black;
  font-weight: bolder;
}
@media print {
  .components-layout-breadcrumb {
    display: none;
  }
}
